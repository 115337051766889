import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import ApiKeysApi from '../generated/api/ApiKeys';

export default (service: Servicelayer) => ({
  ...ApiKeysApi(service),

  // Add custom calls here

});
