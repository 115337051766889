// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import OnlinePaymentTransactionResourceViewModel from '../../viewModel/resource/OnlinePaymentTransactionResourceViewModel';
import OnlinePaymentTransactionUpdatedResourceViewModel from '../../viewModel/resource/OnlinePaymentTransactionUpdatedResourceViewModel';
import WidgetJavaScriptUrlRequestViewModel from '../../viewModel/resource/WidgetJavaScriptUrlRequestViewModel';
import OnlinePaymentTransactionProcessingResourceViewModel from '../../viewModel/resource/OnlinePaymentTransactionProcessingResourceViewModel';

export default (service: Servicelayer) => ({
  /**  */
  async getTransactionById(id: string, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-transaction-by-id'] ?? 'core/OnlinePaymentTransaction/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new OnlinePaymentTransactionResourceViewModel().fromDTO(dto);
  },
  /**  */
  async createTransaction(model: OnlinePaymentTransactionResourceViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-create-transaction'] ?? 'core/OnlinePaymentTransaction/Create';
    const response = await service.post<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as string | undefined;
  },
  /**  */
  async updateTransaction(model: OnlinePaymentTransactionResourceViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-update-transaction'] ?? 'core/OnlinePaymentTransaction/Update';
    const response = await service.post<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const dto = response.data.result;
    return new OnlinePaymentTransactionUpdatedResourceViewModel().fromDTO(dto);
  },
  /**  */
  async getWidgetJavaScriptUrl(id: string, model: WidgetJavaScriptUrlRequestViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-widget-java-script-url'] ?? 'core/OnlinePaymentTransaction/{id}/GetWidgetJavaScriptUrl';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.post<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as string | undefined;
  },
  /**  */
  async processTransaction(id: string, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-process-transaction'] ?? 'core/OnlinePaymentTransaction/{id}/Process';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.post<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new OnlinePaymentTransactionProcessingResourceViewModel().fromDTO(dto);
  },
});
