import coreRoutes from '@glittr/frontend-core/src/routes';
import Home from './views/pages/index.vue';

export default {
  mode: 'history',
  base: process.env!.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    ...coreRoutes,
    {
      path: '/:tenant/:workspace',
      name: 'dashboard',
      alias: '/:tenant/:workspace/dashboard',
      component: () => import('./views/pages/app/cases/index.vue'),
    },
    {
      path: '/:tenant/:workspace/cases',
      name: 'cases',
      component: () => import('./views/pages/app/cases/index.vue'),
    },
    {
      path: '/_admin/testing/cases/legalCases',
      name: 'cases-legalCases-config',
      component: () => import('./views/pages/_admin/testing/widgets/legal-cases-config/legal-cases-config.vue'),
    },
    {
      path: '/_admin/testing/cases/legalCasesDebtors',
      name: 'cases-legalCasesDebtors-config',
      component: () => import('./views/pages/_admin/testing/widgets/legal-cases-debtors-config/legal-cases-debtors-config.vue'),
    },
    {
      path: '/_admin/testing/cases/wageConsultings',
      name: 'cases-wageConsultings-config',
      component: () => import('./views/pages/_admin/testing/widgets/wage-consultings-config/wage-consultings-config.vue'),
    },
    {
      path: '/:tenant/:workspace/cases/legalCases',
      name: 'cases-legal',
      component: () => import('./views/pages/app/cases/legalCases/list.vue'),
    },
    {
      path: '/:tenant/:workspace/cases/legalCases/debtors',
      name: 'cases-legal-debtors',
      component: () => import('./views/pages/app/cases/legalCases/debtors/list.vue'),
    },
    {
      path: '/:tenant/:workspace/cases/legalCases/:id',
      name: 'cases-legal-detail',
      component: () => import('./views/pages/app/cases/legalCases/detail.vue'),
    },
    {
      path: '/:tenant/:workspace/cases/wageConsultings',
      name: 'cases-wageConsulting',
      component: () => import('./views/pages/app/cases/wageConsultings/list.vue'),
    },
    {
      path: '/:tenant/:workspace/cases/wageConsultings/debtors',
      name: 'cases-wageConsulting-debtors',
      component: () => import('./views/pages/app/cases/wageConsultings/debtors/list.vue'),
    },
    {
      path: '/:tenant/:workspace/cases/wageConsultings/:id',
      name: 'cases-wageConsulting-detail',
      component: () => import('./views/pages/app/cases/wageConsultings/detail.vue'),
    }, {
      path: '/_widgets/cases/legal-cases',
      name: 'widget-casesLegalCases',
      component: () => import('./views/widgets/legal-cases/legal-cases.vue'),
    }, {
      path: '/_widgets/cases/legal-cases-debtors',
      name: 'widget-casesLegalCasesDebtros',
      component: () => import('./views/widgets/legal-cases-debtors/legal-cases-debtors.vue'),
    }, {
      path: '/_widgets/cases/wage-consultings',
      name: 'widget-casesWageConsultings',
      component: () => import('./views/widgets/wage-consultings/wage-consultings.vue'),
    },
  ],
};
