import Vue, { VueConstructor } from 'vue';
import generatedApi from './generated/generatedApi';

const loadApi = () => ({
  ...generatedApi(),

  // Add custom apis here

});

export default {
  install: (VuePlugin: VueConstructor, options: any) => {
    VuePlugin.$guards.waitForAppLoad().then(() => {
      const coreApi = VuePlugin.$service.api;
      VuePlugin.$service.api = { ...coreApi, ...loadApi() };
      VuePlugin.prototype.$service.api = Vue.$service.api;
    });
  },
};

type AppServiceType = ReturnType<typeof loadApi>;
declare module '@glittr/frontend-core/src/plugins/servicelayer/servicelayer' {
  interface ServiceAPI extends AppServiceType { }
}
