import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import AppSystemsApi from '../generated/api/AppSystems';

export default (service: Servicelayer) => ({
  ...AppSystemsApi(service),

  // Add custom calls here

});
