// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import ContactIdMappingListResourceViewModel from '../../../../viewModel/resource/ContactIdMappingListResourceViewModel';
import ContactIdMappingListResourceDTO from '../../dto/resource/ContactIdMappingListResourceDTO';

export default abstract class ContactIdMappingListResourceModel extends BaseModel<ContactIdMappingListResourceDTO> {
  /**
  * @type {int64}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  * @type {uuid}
  */
  get crmId() { return this.dto.crmId; }
  set crmId(value) { this.dto.crmId = value; }
  /**
  * @type {int64}
  */
  get foreignAppSystemId() { return this.dto.foreignAppSystemId; }
  set foreignAppSystemId(value) { this.dto.foreignAppSystemId = value; }
  /**
  */
  get foreignAppSystemKey() { return this.dto.foreignAppSystemKey; }
  set foreignAppSystemKey(value) { this.dto.foreignAppSystemKey = value; }
  /**
  */
  get foreignId() { return this.dto.foreignId; }
  set foreignId(value) { this.dto.foreignId = value; }
  /**
  * @type {int32}
  */
  get crmStateId() { return this.dto.crmStateId; }
  set crmStateId(value) { this.dto.crmStateId = value; }
  /**
  */
  get crmStateName() { return this.dto.crmStateName; }
  set crmStateName(value) { this.dto.crmStateName = value; }
  /**
  * @type {int32}
  */
  get mappingStateId() { return this.dto.mappingStateId; }
  set mappingStateId(value) { this.dto.mappingStateId = value; }
  /**
  */
  get mappingStateName() { return this.dto.mappingStateName; }
  set mappingStateName(value) { this.dto.mappingStateName = value; }
  /**
  */
  get mappingStateReason() { return this.dto.mappingStateReason; }
  set mappingStateReason(value) { this.dto.mappingStateReason = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.id);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.id = value;
    this.dto.crmId = qs.crmid;
    value = Number.parseFloat(qs.foreignappsystemid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.foreignAppSystemId = value;
    this.dto.foreignAppSystemKey = qs.foreignappsystemkey;
    this.dto.foreignId = qs.foreignid;
    value = Number.parseFloat(qs.crmstateid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.crmStateId = value;
    this.dto.crmStateName = qs.crmstatename;
    value = Number.parseFloat(qs.mappingstateid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.mappingStateId = value;
    this.dto.mappingStateName = qs.mappingstatename;
    this.dto.mappingStateReason = qs.mappingstatereason;
  }
}
