import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import AppApi from '../generated/api/App';

export default (service: Servicelayer) => ({
  ...AppApi(service),

  // Add custom calls here

});
