<template>
  <p-container v-if="!model.initalized">
    <p-progress-linear class="my-6" indeterminate></p-progress-linear>
  </p-container>
  <p-container v-else>
    <p-row align-center>
      <p-button tertiary @click="$router.back()">
        <p-icon>arrow_back</p-icon>
      </p-button>
      <h1>{{ model.caption }}</h1>
    </p-row>
    <p-row :column="!$media.isDesktop">
      <p-col xs12 sm11 md6 lg4 xl3>
        <p-table dense :zebra="false">
          <template #rows>
            <tr>
              <td class="ix-4">
                {{ $t('page.dossiers.label.customer') }}
              </td><td>{{ model.customer.caption }}</td>
            </tr>
            <tr>
              <td class="ix-4">
                {{ $t('page.dossiers.label.debtor') }}
              </td><td>{{ model.debtor.caption }}</td>
            </tr>
            <tr>
              <td class="ix-4">
                {{ $t('page.dossiers.label.status') }}
              </td><td>{{ model.status }}</td>
            </tr>
          </template>
        </p-table>
        <p-row column>
          <p-col>
            <p-button tertiary link @click="gotoEasydooDossier">
              {{ $t('page.dossiers.label.openInEasyDoo') }}
            </p-button>
          </p-col>
          <p-col>
            <p-button :loading="isLoading" :disabled="isLoading" @click="importEasydooTimeRecords">
              {{ $t('page.dossiers.label.loadCorrections') }}
            </p-button>
            <p-request-alert v-model="errorResponse"></p-request-alert>
          </p-col>
        </p-row>
      </p-col>
      <p-divider :vertical="$media.isDesktop" :class="{'my-6' : !$media.isDesktop}"></p-divider>
      <p-col xs12 sm11 md6 lg4 xl3>
        <p-table dense :zebra="false">
          <template #rows>
            <tr>
              <td class="ix-6">
                {{ $t('page.dossiers.label.timeUnbilled') }}
              </td><td>{{ model.totalTimeUnbilledMinutes }}</td>
            </tr>
            <tr>
              <td class="ix-6">
                {{ $t('page.dossiers.label.timeBilled') }}
              </td><td>{{ model.totalTimeBilledMinutes }}</td>
            </tr>
            <tr>
              <td class="ix-6">
                {{ $t('page.dossiers.label.billedUntil') }}
              </td><td>{{ $format.localDate(model.billedUntil) }}</td>
            </tr>
          </template>
        </p-table>
      </p-col>
    </p-row>
    <p-divider class="my-6"></p-divider>
    <h3 class="mb-2">
      {{ $t('page.dossiers.label.timeRecords') }}
    </h3>
    <p-row>
      <p-col xs12 sm6 md4 lg3>
        <p-date-time-field v-model="filter.from" :label="$t('core.page.appLog.action.from')"></p-date-time-field>
      </p-col>
      <p-col xs12 sm6 md4 lg3>
        <p-date-time-field v-model="filter.to" :label="$t('core.page.appLog.action.to')"></p-date-time-field>
      </p-col>
      <p-col xs12 sm6 md4 lg3>
        <p-select
          v-model="filter.billingStatus"
          :label="$t('core.page.integrationlog.list.status')"
          :items="statusItems"
          context-icon="build"
          clearable
          @input="onFiltering"
        ></p-select>
      </p-col>
    </p-row>

    <p-list-view
      ref="list"
      v-model="timeRecords"
      :service-function="populateTimeData"
      :columns="columns"
      :pagination.sync="innerPagination"
      @selection-change="selection = $event"
    >
      <template slot="table-actions">
        <p-row>
          <p-button @click="refresh">
            <p-icon>sync</p-icon>
          </p-button>
        </p-row>
      </template>
      <template #isBilled="{entry}">
        <p-row justify-center>
          <p-checkbox v-model="entry.value" class="ml-2 mr-0" readonly></p-checkbox>
        </p-row>
      </template>
    </p-list-view>
  </p-container>
</template>

<script lang="ts">
  import WageConsultingListResourceViewModel from '@/src/services/v1/viewModel/resource/WageConsultingListResourceViewModel';
  import WageConsultingResourceViewModel from '@/src/services/v1/viewModel/resource/WageConsultingResourceViewModel';
  import TimeRecordImportRequestViewModel from '@/src/services/v1/viewModel/resource/TimeRecordImportRequestViewModel';
  import TimeRecordListResourceViewModel from '@/src/services/v1/viewModel/resource/TimeRecordListResourceViewModel';
  import IpTableColumn from '@glittr/frontend-core/src/components/p-table/IpTableColumn';
  import ResponsePagingInfo from '@glittr/frontend-core/src/plugins/servicelayer/responseTypes/responsePagingInfo';
  import Vue from 'vue';
  import GetTimeRecordsByWageConsultingPagedListRequestModel from '@/src/services/v1/viewModel/request/Kfmv/GetTimeRecordsByWageConsultingPagedListRequestViewModel';

  export default Vue.extend({
    auth: true,
    props: {

    },
    data: () => ({
      statusItems: [
        { caption: 'Offen', id: 2 },
        { caption: 'Abgerechnet', id: 1 },
      ],
      model: new WageConsultingResourceViewModel(),
      innerPagination: {} as ResponsePagingInfo,
      selection: [] as WageConsultingListResourceViewModel[],
      filter: new GetTimeRecordsByWageConsultingPagedListRequestModel(),
      timeRecords: [] as TimeRecordListResourceViewModel[],
      columns: [
        { value: Vue.$t('page.dossiers.label.caption'), field: 'caption' },
        { value: Vue.$t('page.dossiers.label.category'), field: 'category' },
        { value: Vue.$t('page.dossiers.label.userName'), field: 'userName' },
        {
          value: Vue.$t('page.dossiers.label.startTime'), field: 'startTime', format: (value: string) => (Vue.$format.localDateAndTime(value)),
        },
        {
          value: Vue.$t('page.dossiers.label.endTime'), field: 'endTime', format: (value: string) => (Vue.$format.localDateAndTime(value)),
        },
        { value: Vue.$t('page.dossiers.label.durationMinutes'), field: 'durationMinutes', textAlign: 'right' },
        {
          value: Vue.$t('page.dossiers.label.isBilled'), field: 'isBilled', textAlign: 'center',
        },
      ] as IpTableColumn[],
      /*
      billingStatus: 2
      caption: "Telefongespräch mit Gewerkschaft"
      category: "Beratung"
      created: "0001-01-01T00:00:00"
      creatorId: 0
      durationMinutes: 120
      endTime: "2022-03-01T16:00:00+01:00"
      id: 1
      legalCaseId: 1
      links: []
      startTime: "2022-03-01T14:00:00+01:00"
      */
      isLoading: false,
      errorResponse: undefined as any,
    }),
    computed: {
      dossierId(): number | undefined {
        const id = Number.parseInt(this.$route.params.id, 10);
        if (Number.isNaN(id)) {
          return undefined;
        }
        return id;
      },
      easydooUrl(): string | null | undefined {
        return this.model.links.find((l) => l.rel === 'easydoo')?.href;
      },
    },
    watch: {
      filter: {
        deep: true,
        handler() {
          this.$routerUtils.updateQueryParams(this.filter.toQuery(), { preserve: false });
          this.refresh();
        },
      },
    },
    beforeMount() {
      this.filter.fromQuery(this.$routerUtils.getQueryParams());
      this.populateData();
    },
    methods: {
      onFiltering() {
        this.$set(this.innerPagination, 'currentPage', 1);
      },
      refresh() {
        const ordersList = this.$refs.list as any;
        ordersList?.populateList();
      },
      async populateData() {
        this.model = await this.$service.api.kfmv.getWageConsultingById(this.dossierId!);
      },
      async populateTimeData(params: any) {
        this.filter['paging.CurrentPage'] = this.innerPagination.currentPage;
        this.filter['paging.PageSize'] = this.innerPagination.pageSize;
        this.filter['paging.IncludeCount'] = true;
        this.filter['paging.NoPaging'] = false;
        const response = await this.$service.api.kfmv.getTimeRecordsByWageConsultingPagedList(this.dossierId!, this.filter);
        return response;
      },
      gotoEasydooDossier() {
        window.open(this.easydooUrl!, '_blank');
      },
      async importEasydooTimeRecords() {
        this.isLoading = true;
        try {
          const response = await this.$service.api.kfmv.importTimeRecords(new TimeRecordImportRequestViewModel().fromModel({
            workItemId: this.dossierId,
            dossierType: 2,
          }));
          this.populateData();
          this.refresh();
        } catch (error: any) {
          this.errorResponse = error;
        }
        this.isLoading = false;
      },
    },
  });
</script>
