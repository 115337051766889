import CreateWorkItemRequestModel from '../../generated/types/model/resource/CreateWorkItemRequestModel';
import ContactPersonResourceViewModel from './ContactPersonResourceViewModel';

export default class CreateWorkItemRequestViewModel extends CreateWorkItemRequestModel {
  // For instructions on how to use ViewModels visit:
  // https://glittrsoftware.visualstudio.com/glittr.core/_wiki/wikis/glittr.core.wiki/155/ViewModels-Models-DTOs

  constructor() {
    super();
    this.contactPerson = new ContactPersonResourceViewModel();
  }

  hasDocuments:boolean | undefined | null = undefined;
}
