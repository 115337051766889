var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p-container',{ref:"container"},[_c('p-toolbar',[_c('p-text-field',{attrs:{"context-icon":"search","clearable":"","solo":"","label":_vm.$t('app.search')},on:{"input":_vm.onFiltering},model:{value:(_vm.filter.searchCriteria),callback:function ($$v) {_vm.$set(_vm.filter, "searchCriteria", $$v)},expression:"filter.searchCriteria"}})],1),_c('p-row',[_c('p-col',{attrs:{"xs-12":""}},[_c('div',[_c('p-request-alert',{attrs:{"dense":""},model:{value:(_vm.errorResponse),callback:function ($$v) {_vm.errorResponse=$$v},expression:"errorResponse"}},[_vm._v(" "+_vm._s(_vm.errorResponse)+" ")])],1)])],1),_c('p-list-view',{ref:"list",attrs:{"service-function":_vm.populateData,"columns":_vm.columns,"pagination":_vm.innerPagination},on:{"update:pagination":function($event){_vm.innerPagination=$event},"selection-change":function($event){_vm.selection = $event}},scopedSlots:_vm._u([{key:"entry-actions",fn:function(ref){
var row = ref.row;
return [_c('p-row',{staticClass:"ml-3",attrs:{"dense":"","nowrap":""}},[_c('p-button',{attrs:{"title":_vm.$t('page.dossiers.title')},on:{"click":function($event){return _vm.gotoDossier(row.values.id)}}},[_c('p-icon',[_vm._v("source")])],1),_c('p-button',{attrs:{"disabled":_vm.isCreatingInvoice || !row.values.isBillingEnabled,"loading":_vm.isCreatingInvoice},on:{"click":function($event){return _vm.createInvoice(row.values.id)}}},[_vm._v(" "+_vm._s(_vm.$t('page.debtors.label.settle'))+" ")])],1)]}},{key:"integrationCallStatusId",fn:function(ref){
var row = ref.row;
return [_c('p-row',[_c('p-col',[_c('span',[_vm._v(_vm._s(_vm.$tEnum('core.page.integrationlog.statusId', row.integrationCallStatusId.value ? row.integrationCallStatusId.value : 0 )))])])],1)]}},{key:"hasServiceAgreement",fn:function(ref){
var row = ref.row;
var entry = ref.entry;
return [_c('p-row',{attrs:{"justify-center":""}},[_c('p-checkbox',{staticClass:"ml-2 mr-0",attrs:{"readonly":""},model:{value:(entry.value),callback:function ($$v) {_vm.$set(entry, "value", $$v)},expression:"entry.value"}})],1)]}}]),model:{value:(_vm.debtors),callback:function ($$v) {_vm.debtors=$$v},expression:"debtors"}},[_c('template',{slot:"table-actions"},[_c('p-row',[_c('p-button',{on:{"click":_vm.refresh}},[_c('p-icon',[_vm._v("sync")])],1)],1)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }