// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import AccessTokenResourceViewModel from '../../../../viewModel/resource/AccessTokenResourceViewModel';
import AuthTokenResourceViewModel from '../../../../viewModel/resource/AuthTokenResourceViewModel';
import AuthTokenResourceDTO from '../../dto/resource/AuthTokenResourceDTO';

export default abstract class AuthTokenResourceModel extends BaseModel<AuthTokenResourceDTO> {
  /**
  */
  get accessToken() { return this.getObjectModel(() => this.dto.accessToken, AccessTokenResourceViewModel)!; }
  set accessToken(value) { this.setObjectModel(() => this.dto.accessToken, value); }
  /**
  */
  get refreshToken() { return this.dto.refreshToken; }
  set refreshToken(value) { this.dto.refreshToken = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    this.dto.refreshToken = qs.refreshtoken;
  }
}
