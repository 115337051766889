// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import AppLogResourceViewModel from '../../../../viewModel/resource/AppLogResourceViewModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import PaginationDataResourceViewModel from '../../../../viewModel/resource/PaginationDataResourceViewModel';
import AppLogResourcePagingResultViewModel from '../../../../viewModel/resource/AppLogResourcePagingResultViewModel';
import AppLogResourcePagingResultDTO from '../../dto/resource/AppLogResourcePagingResultDTO';

export default abstract class AppLogResourcePagingResultModel extends BaseModel<AppLogResourcePagingResultDTO> {
  /**
  */
  get items() { return this.getArrayModels(() => this.dto.items, AppLogResourceViewModel)!; }
  set items(value) { this.setArrayModels(() => this.dto.items, value); }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  */
  get paginationData() { return this.getObjectModel(() => this.dto.paginationData, PaginationDataResourceViewModel)!; }
  set paginationData(value) { this.setObjectModel(() => this.dto.paginationData, value); }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
  }
}
