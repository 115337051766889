// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import DebtorListResourceViewModel from '../../../../viewModel/resource/DebtorListResourceViewModel';
import DebtorListResourceDTO from '../../dto/resource/DebtorListResourceDTO';

export default abstract class DebtorListResourceModel extends BaseModel<DebtorListResourceDTO> {
  /**
  * @type {int64}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  * @type {int64}
  */
  get creatorId() { return this.dto.creatorId; }
  set creatorId(value) { this.dto.creatorId = value; }
  /**
  */
  get creatorName() { return this.dto.creatorName; }
  set creatorName(value) { this.dto.creatorName = value; }
  /**
  * @type {date-time}
  */
  get created() { return this.dto.created; }
  set created(value) { this.dto.created = value; }
  /**
  * @type {int64}
  */
  get modifierId() { return this.dto.modifierId; }
  set modifierId(value) { this.dto.modifierId = value; }
  /**
  */
  get modifierName() { return this.dto.modifierName; }
  set modifierName(value) { this.dto.modifierName = value; }
  /**
  * @type {date-time}
  */
  get modified() { return this.dto.modified; }
  set modified(value) { this.dto.modified = value; }
  /**
  */
  get caseType() { return this.dto.caseType; }
  set caseType(value) { this.dto.caseType = value; }
  /**
  * @type {uuid}
  */
  get crmContactId() { return this.dto.crmContactId; }
  set crmContactId(value) { this.dto.crmContactId = value; }
  /**
  * @type {int32}
  */
  get number() { return this.dto.number; }
  set number(value) { this.dto.number = value; }
  /**
  * @type {int32}
  */
  get contactNumber() { return this.dto.contactNumber; }
  set contactNumber(value) { this.dto.contactNumber = value; }
  /**
  */
  get name() { return this.dto.name; }
  set name(value) { this.dto.name = value; }
  /**
  * @type {int32}
  */
  get year() { return this.dto.year; }
  set year(value) { this.dto.year = value; }
  /**
  */
  get hasServiceAgreement() { return this.dto.hasServiceAgreement; }
  set hasServiceAgreement(value) { this.dto.hasServiceAgreement = value; }
  /**
  * @type {double}
  */
  get costUpperLimit() { return this.dto.costUpperLimit; }
  set costUpperLimit(value) { this.dto.costUpperLimit = value; }
  /**
  * @type {int32}
  */
  get caseCount() { return this.dto.caseCount; }
  set caseCount(value) { this.dto.caseCount = value; }
  /**
  * @type {double}
  */
  get caseBilledAmount() { return this.dto.caseBilledAmount; }
  set caseBilledAmount(value) { this.dto.caseBilledAmount = value; }
  /**
  * @type {double}
  */
  get caseExceedingAmount() { return this.dto.caseExceedingAmount; }
  set caseExceedingAmount(value) { this.dto.caseExceedingAmount = value; }
  /**
  * @type {double}
  */
  get caseOpenAmount() { return this.dto.caseOpenAmount; }
  set caseOpenAmount(value) { this.dto.caseOpenAmount = value; }
  /**
  * @type {double}
  */
  get hourlyRate() { return this.dto.hourlyRate; }
  set hourlyRate(value) { this.dto.hourlyRate = value; }
  /**
  * @type {date-time}
  */
  get billedUntilDate() { return this.dto.billedUntilDate; }
  set billedUntilDate(value) { this.dto.billedUntilDate = value; }
  /**
  * @type {int64}
  */
  get integrationCallId() { return this.dto.integrationCallId; }
  set integrationCallId(value) { this.dto.integrationCallId = value; }
  /**
  * @type {int32}
  */
  get integrationCallStatusId() { return this.dto.integrationCallStatusId; }
  set integrationCallStatusId(value) { this.dto.integrationCallStatusId = value; }
  /**
  */
  get costWarningLight() { return this.dto.costWarningLight; }
  set costWarningLight(value) { this.dto.costWarningLight = value; }
  /**
  */
  get isBillingEnabled() { return this.dto.isBillingEnabled; }
  set isBillingEnabled(value) { this.dto.isBillingEnabled = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.id);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.id = value;
    value = Number.parseFloat(qs.creatorid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.creatorId = value;
    this.dto.creatorName = qs.creatorname;
    this.dto.created = qs.created;
    value = Number.parseFloat(qs.modifierid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.modifierId = value;
    this.dto.modifierName = qs.modifiername;
    this.dto.modified = qs.modified;
    this.dto.caseType = qs.casetype;
    this.dto.crmContactId = qs.crmcontactid;
    value = Number.parseFloat(qs.number);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.number = value;
    value = Number.parseFloat(qs.contactnumber);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.contactNumber = value;
    this.dto.name = qs.name;
    value = Number.parseFloat(qs.year);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.year = value;
    value = qs.hasServiceAgreement === 'true' ? true : qs.hasserviceagreement;
    value = qs.hasServiceAgreement === 'false' ? false : undefined;
    this.dto.hasServiceAgreement = value;
    value = Number.parseFloat(qs.costupperlimit);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.costUpperLimit = value;
    value = Number.parseFloat(qs.casecount);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.caseCount = value;
    value = Number.parseFloat(qs.casebilledamount);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.caseBilledAmount = value;
    value = Number.parseFloat(qs.caseexceedingamount);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.caseExceedingAmount = value;
    value = Number.parseFloat(qs.caseopenamount);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.caseOpenAmount = value;
    value = Number.parseFloat(qs.hourlyrate);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.hourlyRate = value;
    this.dto.billedUntilDate = qs.billeduntildate;
    value = Number.parseFloat(qs.integrationcallid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.integrationCallId = value;
    value = Number.parseFloat(qs.integrationcallstatusid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.integrationCallStatusId = value;
    this.dto.costWarningLight = qs.costwarninglight;
    value = qs.isBillingEnabled === 'true' ? true : qs.isbillingenabled;
    value = qs.isBillingEnabled === 'false' ? false : undefined;
    this.dto.isBillingEnabled = value;
  }
}
