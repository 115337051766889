import legalCasesDebtorsVueSchema from './legal-cases-debtors.vue.schema';
import LegalCasesViewModel from '../legal-cases/legal-cases.viewmodel';

export default class LegalCasesDebtorsViewModel extends LegalCasesViewModel {
  constructor(isContributionsArePaid:boolean | null = null) {
    super(true, legalCasesDebtorsVueSchema);
    if (isContributionsArePaid !== null) {
      this.data.legalCase_IsContributionsArePaid = isContributionsArePaid;
    }
  }
}
