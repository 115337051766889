<template>
  <p-container ref="container">
    <p-toolbar>
      <p-text-field
        v-model="filter.searchCriteria"
        context-icon="search"
        clearable
        solo
        :label="$t('app.search')"
        @input="onFiltering"
      ></p-text-field>
    </p-toolbar>
    <p-row>
      <p-col xs-12>
        <div>
          <p-request-alert v-model="errorResponse" dense>
            {{ errorResponse }}
          </p-request-alert>
        </div>
        <!-- <div>
          <p-alert v-model="processMessage" dense type="success">
            {{ processMessage }}
          </p-alert>
        </div> -->
      </p-col>
    </p-row>
    <p-list-view
      ref="list"
      v-model="debtors"
      :service-function="populateData"
      :columns="columns"
      :pagination.sync="innerPagination"
      @selection-change="selection = $event"
    >
      <template slot="table-actions">
        <p-row>
          <p-button @click="refresh">
            <p-icon>sync</p-icon>
          </p-button>
        </p-row>
      </template>
      <template #entry-actions="{row}">
        <p-row class="ml-3" dense nowrap>
          <p-button :title="$t('page.dossiers.title')" @click="gotoDossier(row.values.id)">
            <p-icon>source</p-icon>
          </p-button>
          <p-button :disabled="isCreatingInvoice || !row.values.isBillingEnabled" :loading="isCreatingInvoice" @click="createInvoice(row.values.id)">
            {{ $t('page.debtors.label.settle') }}
          </p-button>
        </p-row>
      </template>
      <template #integrationCallStatusId="{row}">
        <p-row>
          <p-col>
            <span>{{ $tEnum('core.page.integrationlog.statusId', row.integrationCallStatusId.value ? row.integrationCallStatusId.value : 0 ) }}</span>
          </p-col>
        </p-row>
      </template>
      <template #hasServiceAgreement="{row, entry}">
        <p-row justify-center>
          <p-checkbox v-model="entry.value" class="ml-2 mr-0" readonly></p-checkbox>
        </p-row>
      </template>
    </p-list-view>
  </p-container>
</template>

<script lang="ts">
  import GetDebtorPagedListRequestViewModel from '@/src/services/v1/viewModel/request/Kfmv/GetDebtorPagedListRequestViewModel';
  import IpTableColumn from '@glittr/frontend-core/src/components/p-table/IpTableColumn';
  import ResponsePagingInfo from '@glittr/frontend-core/src/plugins/servicelayer/responseTypes/responsePagingInfo';
  import Vue from 'vue';
  import DebtorListResourceViewModel from '@/src/services/v1/viewModel/resource/DebtorListResourceViewModel';

  export default Vue.extend({
    auth: true,
    props: {

    },
    data: () => ({
      dossierType: 'LB',
      isCreatingInvoice: false,
      innerPagination: {} as ResponsePagingInfo,
      selection: [] as any[],
      filter: new GetDebtorPagedListRequestViewModel(),
      debtors: [] as DebtorListResourceViewModel[],
      columns: [
        { value: Vue.$t('page.debtors.label.number'), field: 'number' },
        { value: Vue.$t('page.debtors.label.name'), field: 'name' },
        {
          value: Vue.$t('page.debtors.label.serviceAgreement'), field: 'hasServiceAgreement', textAlign: 'center',
        },
        {
          value: Vue.$t('page.debtors.label.caseCount'), field: 'caseCount', textAlign: 'right',
        },
        {
          value: Vue.$t('page.debtors.label.hourlyRate'), field: 'hourlyRate', textAlign: 'right', format: (value: number) => Vue.$format.currency(value, 'CHF'),
        },
        // {
        //   value: Vue.$t('page.debtors.label.costUpperLimit'), field: 'costUpperLimit', textAlign: 'right', format: (value: number) => Vue.$format.currency(value, 'CHF'),
        // },
        {
          value: Vue.$t('page.debtors.label.billedAmount'), field: 'caseBilledAmount', textAlign: 'right', format: (value: number) => Vue.$format.currency(value, 'CHF'),
        },
        {
          value: Vue.$t('page.debtors.label.unbilledAmount'), field: 'caseOpenAmount', textAlign: 'right', format: (value: number) => Vue.$format.currency(value, 'CHF'),
        },
        {
          value: Vue.$t('page.debtors.label.billedUntil'), field: 'billedUntilDate', format: (value: string) => Vue.$format.localDate(value),
        },
        {
          value: Vue.$t('page.debtors.label.billingStatus'), field: 'integrationCallStatusId',
        },
      ] as IpTableColumn[],
      errorResponse: undefined as any,
      processMessage: undefined as any,
    }),
    computed: {
      hasNoSelection(): boolean {
        return this.selection.length <= 0;
      },
    },
    watch: {
      filter: {
        deep: true,
        handler() {
          this.refresh();
        },
      },
    },
    beforeMount() {
      this.filter.fromQuery(this.$routerUtils.getQueryParams());
    },
    methods: {
      onFiltering() {
        this.$routerUtils.updateQueryParams(this.filter.toQuery(), { preserve: false });
        this.$set(this.innerPagination, 'currentPage', 1);
      },
      gotoDossier(debtorId: any) {
        this.$router.push(`/kfmv/il/cases/wageConsultings?debtorId=${debtorId}`);
      },
      refresh() {
        const ordersList = this.$refs.list as any;
        ordersList?.populateList();
      },
      async populateData(params: any) {
        this.filter.dossierType = this.dossierType;
        this.filter['paging.CurrentPage'] = this.innerPagination.currentPage;
        this.filter['paging.PageSize'] = this.innerPagination.pageSize;
        this.filter['paging.IncludeCount'] = true;
        this.filter['paging.NoPaging'] = false;
        const response = await this.$service.api.kfmv.getDebtorPagedList(this.filter);
        return response;
      },
      scrollToTop(node?: Vue | Element) {
        if (!node) return;
        try {
          const el = this._.getElement(node) as HTMLElement;
          let parent = el;
          while (parent) {
            parent.scrollTo({ top: 0 });
            parent = parent.parentElement as HTMLElement;
          }
        } catch (error: any) { /* */ }
      },
      async createInvoice(debtorId: any) {
        this.isCreatingInvoice = true;
        // clear existing messages
        this.errorResponse = undefined;
        this.processMessage = undefined;
        try {
          const billingPeriod = await this.$service.api.kfmv.getBillingInfo(debtorId, this.dossierType);
          let confirmMessage = `${this.$t('page.debtors.actions.doYouReallyWantToSettle')}\n`;
          confirmMessage += '\n';
          confirmMessage += `${this.$t('page.dossiers.label.debtor')}: ${billingPeriod.debtor.caption}\n`;
          confirmMessage += `${this.$t('page.dossiers.label.period')}: ${this.$format.localDate(billingPeriod.periodFrom!)} - ${this.$format.localDate(billingPeriod.periodTo!)}\n`;
          confirmMessage += `${this.$t('page.debtors.label.caseCount')}: ${billingPeriod.casesCount}\n`;
          confirmMessage += `${this.$t('page.debtors.label.minutesCount')}: ${billingPeriod.totalMinutes}\n`;

          let isConfirmed = false;

          if ((billingPeriod.totalMinutes ?? 0) > 0) {
            isConfirmed = await this.$dialog.confirm(this.$t('page.debtors.label.settle'), confirmMessage);
          } else {
            confirmMessage += `\n⚠️ ${this.$t('page.debtors.notification.noCasesToBill')}\n`;
            await this.$dialog.notify(this.$t('page.debtors.label.settle'), confirmMessage);
          }

          if (isConfirmed) {
            await this.$service.api.kfmv.createDebtorInvoice(debtorId, this.dossierType);
            this.processMessage = this.$t('page.debtors.actions.createInvoicesSuccess', 1);
          }
        } catch (error: any) {
          this.scrollToTop(this.$refs.container as Vue);
          this.$log.error(error);
          this.errorResponse = this.$t('page.debtors.actions.createInvoicesError', error?.message?.toString() ?? error?.toString());
        }
        this.refresh();
        this.isCreatingInvoice = false;
      },
    },
  });
</script>
