import queryBuilder from '@glittr/frontend-core/src/plugins/validation/queryBuilder';
import validationPatterns from '@glittr/frontend-core/src/plugins/validation/validationPatterns';

export default {
  ...queryBuilder.properties({
    hasDocuments: {
      type: 'boolean', required: true, enum: [true], title: 'Mind. ein Dokument muss hochgeladen werden.',
    },
    recaptchaToken: { type: 'string', required: true, title: 'Ich bin kein Roboter' },
    acceptGdpr: { type: 'boolean', required: true, enum: [true] },
    wageConsulting_Topic: { type: 'string', required: true },

    contactPerson: {
      properties: {
        salutation: { type: 'integer', required: true },
        firstName: { type: 'string', required: true },
        lastName: { type: 'string', required: true },
        eMail: { type: 'string', pattern: validationPatterns.email, required: true },
        birthDate: { type: 'string', format: 'date-time', required: true },
        phoneNumberMobile: { type: 'string', pattern: validationPatterns.phone, required: true },
        phoneNumberHome: { type: 'string', pattern: validationPatterns.phone, required: false },
        phoneNumberCompany: { type: 'string', pattern: validationPatterns.phone, required: false },
        street: { type: 'string', required: true },
        houseNumber: { type: 'string', required: false },
        memberNumber: { type: 'integer', required: false },
        postalCode: { type: 'string', required: true },
        city: { type: 'string', required: true },
      },
    },
  }),
};
