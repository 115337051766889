import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import ProcessesApi from '../generated/api/Processes';

export default (service: Servicelayer) => ({
  ...ProcessesApi(service),

  // Add custom calls here

});
