import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import AppLogsApi from '../generated/api/AppLogs';

export default (service: Servicelayer) => ({
  ...AppLogsApi(service),

  // Add custom calls here

});
