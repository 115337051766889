// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ContactPersonResourceViewModel from '../../../../viewModel/resource/ContactPersonResourceViewModel';
import ContactPersonResourceDTO from '../../dto/resource/ContactPersonResourceDTO';

export default abstract class ContactPersonResourceModel extends BaseModel<ContactPersonResourceDTO> {
  /**
  * @type {int32}
  */
  get salutation() { return this.dto.salutation; }
  set salutation(value) { this.dto.salutation = value; }
  /**
  */
  get firstName() { return this.dto.firstName; }
  set firstName(value) { this.dto.firstName = value; }
  /**
  */
  get lastName() { return this.dto.lastName; }
  set lastName(value) { this.dto.lastName = value; }
  /**
  * @type {email}
  */
  get eMail() { return this.dto.eMail; }
  set eMail(value) { this.dto.eMail = value; }
  /**
  * @type {date-time}
  */
  get birthDate() { return this.dto.birthDate; }
  set birthDate(value) { this.dto.birthDate = value; }
  /**
  */
  get phoneNumberMobile() { return this.dto.phoneNumberMobile; }
  set phoneNumberMobile(value) { this.dto.phoneNumberMobile = value; }
  /**
  */
  get phoneNumberHome() { return this.dto.phoneNumberHome; }
  set phoneNumberHome(value) { this.dto.phoneNumberHome = value; }
  /**
  */
  get phoneNumberCompany() { return this.dto.phoneNumberCompany; }
  set phoneNumberCompany(value) { this.dto.phoneNumberCompany = value; }
  /**
  */
  get street() { return this.dto.street; }
  set street(value) { this.dto.street = value; }
  /**
  */
  get houseNumber() { return this.dto.houseNumber; }
  set houseNumber(value) { this.dto.houseNumber = value; }
  /**
  */
  get houseNumberAddition() { return this.dto.houseNumberAddition; }
  set houseNumberAddition(value) { this.dto.houseNumberAddition = value; }
  /**
  */
  get postalCode() { return this.dto.postalCode; }
  set postalCode(value) { this.dto.postalCode = value; }
  /**
  */
  get city() { return this.dto.city; }
  set city(value) { this.dto.city = value; }
  /**
  */
  get countryCode() { return this.dto.countryCode; }
  set countryCode(value) { this.dto.countryCode = value; }
  /**
  * @type {int32}
  */
  get memberNumber() { return this.dto.memberNumber; }
  set memberNumber(value) { this.dto.memberNumber = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.salutation);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.salutation = value;
    this.dto.firstName = qs.firstname;
    this.dto.lastName = qs.lastname;
    this.dto.eMail = qs.email;
    this.dto.birthDate = qs.birthdate;
    this.dto.phoneNumberMobile = qs.phonenumbermobile;
    this.dto.phoneNumberHome = qs.phonenumberhome;
    this.dto.phoneNumberCompany = qs.phonenumbercompany;
    this.dto.street = qs.street;
    this.dto.houseNumber = qs.housenumber;
    this.dto.houseNumberAddition = qs.housenumberaddition;
    this.dto.postalCode = qs.postalcode;
    this.dto.city = qs.city;
    this.dto.countryCode = qs.countrycode;
    value = Number.parseFloat(qs.membernumber);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.memberNumber = value;
  }
}
