// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetCallLogsRequestViewModel from '../../viewModel/request/CallLogs/GetCallLogsRequestViewModel';
import CallLogWithDurationResourcePagingResultViewModel from '../../viewModel/resource/CallLogWithDurationResourcePagingResultViewModel';
import CallLogWithDurationResourceViewModel from '../../viewModel/resource/CallLogWithDurationResourceViewModel';

export default (service: Servicelayer) => ({
  /**  */
  async getCallLogs(request: GetCallLogsRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-call-logs'] ?? 'core/admin/CallLogs';
    const response = await service.get<any>(endpointPath, {
      query: {
        correlationId: requestDTO.correlationId,
        from: requestDTO.from,
        to: requestDTO.to,
        requestPath: requestDTO.requestPath,
        requestTypes: requestDTO.requestTypes,
        targetSystems: requestDTO.targetSystems,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return new CallLogWithDurationResourcePagingResultViewModel().fromDTO(dto);
  },
  /**  */
  async getCallLogById(id: number, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-call-log-by-id'] ?? 'core/admin/CallLogs/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new CallLogWithDurationResourceViewModel().fromDTO(dto);
  },
});
