import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import SequencenumbersApi from '../generated/api/Sequencenumbers';

export default (service: Servicelayer) => ({
  ...SequencenumbersApi(service),

  // Add custom calls here

});
