import WorkItemUploadDocumentRequestViewModel from '@/src/services/v1/viewModel/request/WorkItems/WorkItemUploadDocumentRequestViewModel';
import CreateWorkItemRequestViewModel from '@/src/services/v1/viewModel/resource/CreateWorkItemRequestViewModel';
import Vue from 'vue';
import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import GuidLookupResourceListResultModel from '@/src/services/v1/generated/types/model/resource/GuidLookupResourceListResultModel';
import legalCasesVueSchema from './legal-cases.vue.schema';

export default class LegalCasesViewModel {
  isLoading = true;

  isSuccessfullySent = false;

  data: CreateWorkItemRequestViewModel = new CreateWorkItemRequestViewModel();

  accessibilities:GuidLookupResourceListResultModel | undefined = undefined;

  sections:GuidLookupResourceListResultModel | undefined = undefined;

  reCaptchaSitekey:string = (Vue.$config.values as any)['re-captcha-site-key']!;

  Salutations: { caption: string; id: number; }[] = [
    { caption: Vue.$t('core.address.salutationEnum1'), id: 1 },
    { caption: Vue.$t('core.address.salutationEnum2'), id: 2 },
    { caption: Vue.$t('core.address.salutationEnum3'), id: 3 },
  ];

  maxDateOfBirthMax = Vue.$date.now().add(-18, 'year').format('YYYY-MM-DD');

  private documents: File[] = ([] as File[]);

  public get schema():{
    type: IJSONSchema['type'];
    required: IJSONSchema['required'];
    properties: IJSONSchema['properties'];
  } { return this.baseschema; }

  constructor(includeSection:boolean = false, private baseschema:{
    type: IJSONSchema['type'];
    required: IJSONSchema['required'];
    properties: IJSONSchema['properties'];
  } = legalCasesVueSchema) {
    this.data.dossierType = 'RD';
    this.data.contactPerson.countryCode = 'CH';
    Vue.$service.api.workItems.getAccessibilityLookup(this.data.dossierType).then((x) => {
      this.accessibilities = x;
      if (includeSection === true) {
        Vue.$service.api.kfmv.getSectionLookup().then((y) => {
          this.sections = y;
          this.setIsLoading(false);
        });
      } else { this.setIsLoading(false); }
    });
  }

  get DocumentsToBig(): boolean {
    if (this.documents.length === 0) return false;
    return this.documents.map((x) => x.size).reduce((a, b) => a + b) > 10485760;
  }

  get Documents(): File[] {
    return this.documents;
  }

  set Documents(files: File[]) {
    this.documents = files;
  }

  setIsLoading(v: boolean): void {
    this.isLoading = v;
  }

  async save() {
    this.setIsLoading(true);
    try {
      if (this.data.validateWithSchema(this.schema)) {
        this.data.documentIds = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const document of this.documents) {
          const documentuploadRequest = new WorkItemUploadDocumentRequestViewModel();
          documentuploadRequest.uploadFile = document;
          // eslint-disable-next-line no-await-in-loop
          const documentId = await Vue.$service.api.workItems.workItemUploadDocument(documentuploadRequest);
          if (documentId) this.data.documentIds?.push(documentId);
        }
        await Vue.$service.api.workItems.createWorkItem(this.data);
        this.isSuccessfullySent = true;
      }
    } finally { this.setIsLoading(false); }
  }
}
