// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import OnlinePaymentOrderItemResourceViewModel from '../../../../viewModel/resource/OnlinePaymentOrderItemResourceViewModel';
import OnlinePaymentOrderItemResourceDTO from '../../dto/resource/OnlinePaymentOrderItemResourceDTO';

export default abstract class OnlinePaymentOrderItemResourceModel extends BaseModel<OnlinePaymentOrderItemResourceDTO> {
  /**
  */
  get productId() { return this.dto.productId; }
  set productId(value) { this.dto.productId = value; }
  /**
  */
  get sku() { return this.dto.sku; }
  set sku(value) { this.dto.sku = value; }
  /**
  */
  get name() { return this.dto.name; }
  set name(value) { this.dto.name = value; }
  /**
  * @type {double}
  */
  get unitPrice() { return this.dto.unitPrice; }
  set unitPrice(value) { this.dto.unitPrice = value; }
  /**
  * @type {double}
  */
  get discountFraction() { return this.dto.discountFraction; }
  set discountFraction(value) { this.dto.discountFraction = value; }
  /**
  * @type {double}
  */
  get quantity() { return this.dto.quantity; }
  set quantity(value) { this.dto.quantity = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    this.dto.productId = qs.productid;
    this.dto.sku = qs.sku;
    this.dto.name = qs.name;
    value = Number.parseFloat(qs.unitprice);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.unitPrice = value;
    value = Number.parseFloat(qs.discountfraction);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.discountFraction = value;
    value = Number.parseFloat(qs.quantity);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.quantity = value;
  }
}
