<template>
  <p-container>
    <p-toolbar>
      <p-text-field
        v-model="filter.searchCriteria"
        context-icon="search"
        clearable
        solo
        :label="$t('app.search')"
        @input="onFiltering"
      ></p-text-field>
      <il-debtor-lookup
        v-model="filter.debtorId"
        :dossier-type="dossierType"
        clearable
        solo
        @input="onFiltering"
      ></il-debtor-lookup>
      <p-select
        v-model="filter.billingStatus"
        context-icon="build"
        :label="$t('core.page.integrationlog.list.status')"
        clearable
        :items="statusItems"
        solo
        @input="onFiltering"
      ></p-select>
    </p-toolbar>
    <p-list-view
      ref="list"
      v-model="cases"
      :service-function="populateData"
      :columns="columns"
      :pagination.sync="innerPagination"
      @selection-change="selection = $event"
    >
      <template slot="table-actions">
        <p-row>
          <p-button @click="refresh">
            <p-icon>sync</p-icon>
          </p-button>
        </p-row>
      </template>
      <template #entry-actions="{ row }">
        <p-button @click="gotoDossier(row.values.id)">
          <p-icon>file_open</p-icon>
        </p-button>
      </template>
    </p-list-view>
  </p-container>
</template>

<script lang="ts">
  import GetWageConsultingssPagedListRequestViewModel from '@/src/services/v1/viewModel/request/Kfmv/GetWageConsultingsPagedListRequestViewModel';
  import WageConsultingListResourceViewModel from '@/src/services/v1/viewModel/resource/WageConsultingListResourceViewModel';
  import IpTableColumn from '@glittr/frontend-core/src/components/p-table/IpTableColumn';
  import ResponsePagingInfo from '@glittr/frontend-core/src/plugins/servicelayer/responseTypes/responsePagingInfo';
  import Vue from 'vue';

  export default Vue.extend({
    auth: true,
    props: {

    },
    data: () => ({
      dossierType: 'LB',
      statusItems: [
        { caption: 'Offen', id: 2 },
        { caption: 'Abgerechnet', id: 1 },
      ],
      innerPagination: {} as ResponsePagingInfo,
      selection: [] as WageConsultingListResourceViewModel[],
      filter: new GetWageConsultingssPagedListRequestViewModel(),
      cases: [] as WageConsultingListResourceViewModel[],
      columns: [
        { value: Vue.$t('page.dossiers.label.number'), field: 'number' },
        { value: Vue.$t('page.dossiers.label.caption'), field: 'caption' },
        { value: Vue.$t('page.dossiers.label.debtor'), field: 'debtor' },
        { value: Vue.$t('page.dossiers.label.status'), field: 'status' },
        { value: Vue.$t('page.dossiers.label.timeBilled'), field: 'totalTimeBilledMinutes' },
        { value: Vue.$t('page.dossiers.label.timeUnbilled'), field: 'totalTimeUnbilledMinutes' },
      ] as IpTableColumn[],
    }),
    computed: {
    },
    watch: {
      filter: {
        deep: true,
        handler() {
          this.$routerUtils.updateQueryParams(this.filter.toQuery(), { preserve: false });
          this.refresh();
        },
      },
    },
    beforeMount() {
      this.filter.fromQuery(this.$routerUtils.getQueryParams());
    },
    methods: {
      onFiltering() {
        this.$set(this.innerPagination, 'currentPage', 1);
      },
      gotoDossier(dossierId: any) {
        this.$router.push(`/kfmv/il/cases/wageConsultings/${dossierId}`);
      },
      refresh() {
        const ordersList = this.$refs.list as any;
        ordersList?.populateList();
      },
      async populateData(params: any) {
        this.filter['paging.CurrentPage'] = this.innerPagination.currentPage;
        this.filter['paging.PageSize'] = this.innerPagination.pageSize;
        this.filter['paging.IncludeCount'] = true;
        this.filter['paging.NoPaging'] = false;
        const response = await this.$service.api.kfmv.getWageConsultingsPagedList(this.filter);
        return response;
      },
    },
  });
</script>
