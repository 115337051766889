// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import WorkItemUploadDocumentRequestViewModel from '../../viewModel/request/WorkItems/WorkItemUploadDocumentRequestViewModel';
import GuidLookupResourceListResultViewModel from '../../viewModel/resource/GuidLookupResourceListResultViewModel';
import CreateWorkItemRequestViewModel from '../../viewModel/resource/CreateWorkItemRequestViewModel';

export default (service: Servicelayer) => ({
  /**  */
  async getAccessibilityLookup(dossierType: string, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-accessibility-lookup'] ?? 'WorkItems/AccessibilityLookup/{dossierType}';
    endpointPath = endpointPath.replace(/\{dossierType\}/g, `${dossierType}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return new GuidLookupResourceListResultViewModel().fromDTO(dto);
  },
  /**  */
  async createWorkItem(model: CreateWorkItemRequestViewModel, config?: RequestConfig) {
    const modelDTO = model.toDTO();
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-create-work-item'] ?? 'WorkItems';
    const response = await service.post<any>(endpointPath, {
      body: modelDTO,
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as number | undefined;
  },
  /**  */
  async workItemUploadDocument(request: WorkItemUploadDocumentRequestViewModel, config?: RequestConfig) {
    const requestDTO = request.toDTO();
    const formData = new FormData();
    formData.append('uploadFile', requestDTO.uploadFile as Blob);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-work-item-upload-document'] ?? 'WorkItems/UploadDocument';
    const response = await service.post<any>(endpointPath, {
      body: formData,
      headers: {
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return result as string | undefined;
  },
});
