// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ContactPersonResourceViewModel from '../../../../viewModel/resource/ContactPersonResourceViewModel';
import CreateWorkItemRequestViewModel from '../../../../viewModel/resource/CreateWorkItemRequestViewModel';
import CreateWorkItemRequestDTO from '../../dto/resource/CreateWorkItemRequestDTO';

export default abstract class CreateWorkItemRequestModel extends BaseModel<CreateWorkItemRequestDTO> {
  /**
  */
  get dossierType() { return this.dto.dossierType; }
  set dossierType(value) { this.dto.dossierType = value; }
  /**
  */
  get description() { return this.dto.description; }
  set description(value) { this.dto.description = value; }
  /**
  */
  get contactPerson() { return this.getObjectModel(() => this.dto.contactPerson, ContactPersonResourceViewModel)!; }
  set contactPerson(value) { this.setObjectModel(() => this.dto.contactPerson, value); }
  /**
  */
  get accessibilities() { return this.dto.accessibilities; }
  set accessibilities(value) { this.dto.accessibilities = value; }
  /**
  */
  get documentIds() { return this.dto.documentIds; }
  set documentIds(value) { this.dto.documentIds = value; }
  /**
  */
  get acceptGdpr() { return this.dto.acceptGdpr; }
  set acceptGdpr(value) { this.dto.acceptGdpr = value; }
  /**
  */
  get recaptchaToken() { return this.dto.recaptchaToken; }
  set recaptchaToken(value) { this.dto.recaptchaToken = value; }
  /**
  */
  get legalCase_SectionPersonName() { return this.dto.legalCase_SectionPersonName; }
  set legalCase_SectionPersonName(value) { this.dto.legalCase_SectionPersonName = value; }
  /**
  */
  get legalCase_SektionPersonPhone() { return this.dto.legalCase_SektionPersonPhone; }
  set legalCase_SektionPersonPhone(value) { this.dto.legalCase_SektionPersonPhone = value; }
  /**
  */
  get legalCase_SectionPersonEmail() { return this.dto.legalCase_SectionPersonEmail; }
  set legalCase_SectionPersonEmail(value) { this.dto.legalCase_SectionPersonEmail = value; }
  /**
  * @type {uuid}
  */
  get legalCase_ServicePartnerId() { return this.dto.legalCase_ServicePartnerId; }
  set legalCase_ServicePartnerId(value) { this.dto.legalCase_ServicePartnerId = value; }
  /**
  * @type {date-time}
  */
  get legalCase_MemberEntryDate() { return this.dto.legalCase_MemberEntryDate; }
  set legalCase_MemberEntryDate(value) { this.dto.legalCase_MemberEntryDate = value; }
  /**
  * @type {date-time}
  */
  get legalCase_MemberExitDate() { return this.dto.legalCase_MemberExitDate; }
  set legalCase_MemberExitDate(value) { this.dto.legalCase_MemberExitDate = value; }
  /**
  */
  get legalCase_IsContributionsArePaid() { return this.dto.legalCase_IsContributionsArePaid; }
  set legalCase_IsContributionsArePaid(value) { this.dto.legalCase_IsContributionsArePaid = value; }
  /**
  * @type {uuid}
  */
  get wageConsulting_Topic() { return this.dto.wageConsulting_Topic; }
  set wageConsulting_Topic(value) { this.dto.wageConsulting_Topic = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    this.dto.dossierType = qs.dossiertype;
    this.dto.description = qs.description;
    value = qs.acceptGdpr === 'true' ? true : qs.acceptgdpr;
    value = qs.acceptGdpr === 'false' ? false : undefined;
    this.dto.acceptGdpr = value;
    this.dto.recaptchaToken = qs.recaptchatoken;
    this.dto.legalCase_SectionPersonName = qs.legalcase_sectionpersonname;
    this.dto.legalCase_SektionPersonPhone = qs.legalcase_sektionpersonphone;
    this.dto.legalCase_SectionPersonEmail = qs.legalcase_sectionpersonemail;
    this.dto.legalCase_ServicePartnerId = qs.legalcase_servicepartnerid;
    this.dto.legalCase_MemberEntryDate = qs.legalcase_memberentrydate;
    this.dto.legalCase_MemberExitDate = qs.legalcase_memberexitdate;
    value = qs.legalCase_IsContributionsArePaid === 'true' ? true : qs.legalcase_iscontributionsarepaid;
    value = qs.legalCase_IsContributionsArePaid === 'false' ? false : undefined;
    this.dto.legalCase_IsContributionsArePaid = value;
    this.dto.wageConsulting_Topic = qs.wageconsulting_topic;
  }
}
