import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import HttpMethodLookupApi from '../generated/api/HttpMethodLookup';

export default (service: Servicelayer) => ({
  ...HttpMethodLookupApi(service),

  // Add custom calls here

});
