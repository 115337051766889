<template>
  <p-form v-if="!vm.isSuccessfullySent && !vm.isLoading" :model="vm.data" :schema="vm.schema">
    <p-container class="RegistrationLegalCaseDebtorsWrapper">
      <p-row class="TitleWrapper">
        <p-col class="Title">
          <h1 class="headerTitle">
            {{ $t('kfmv-zh.legal-cases-debtors.title') }}
          </h1>
        </p-col>
      </p-row>
      <p-row class="TeaserWrapper">
        <p-col class="Teaser">
          {{ $t('kfmv-zh.legal-cases-debtors.teaser') }}
        </p-col>
      </p-row>
      <p-row class="SalutationTeaserWrapper">
        <p-col class="salutation">
          <p-select v-model="vm.data.contactPerson.salutation" :label="$t('core.address.salutation')" :items="vm.Salutations" required></p-select>
        </p-col>
      </p-row>
      <p-row class="PersonNameWrapper">
        <p-col class="FirstName">
          <p-text-field v-model="vm.data.contactPerson.firstName" class="inputFirstName" :label="$t('core.address.firstName')" required></p-text-field>
        </p-col>
        <p-col class="LastName">
          <p-text-field v-model="vm.data.contactPerson.lastName" class="inputLastName" :label="$t('core.address.lastName')" required></p-text-field>
        </p-col>
      </p-row>
      <p-row class="EmailWrapper">
        <p-col class="Email">
          <p-text-field v-model="vm.data.contactPerson.eMail" class="inputEmail" :label="$t('core.address.email')" required></p-text-field>
        </p-col>
      </p-row>
      <p-row class="DateOfBirthWrapper">
        <p-col class="DateOfBirth">
          <p-date-field
            v-model="vm.data.contactPerson.birthDate"
            class="inputDateOfBirth"
            required
            display-formatter="D.M.YYYY"
            :label="$t('core.address.birthDate')"
            :placeholder="$format.localDate(vm.maxDateOfBirthMax)"
          ></p-date-field>
        </p-col>
      </p-row>
      <p-row class="MemberNumberWrapper">
        <p-col class="MemberNumber">
          <p-number-field v-model="vm.data.contactPerson.memberNumber" no-steppers class="inputMemberNumber" :label="$t('kfmv-zh.address.memberNumber')"></p-number-field>
        </p-col>
      </p-row>
      <p-row class="PhoneNumbersWrapper">
        <p-col class="Mobile">
          <p-phone-field v-model="vm.data.contactPerson.phoneNumberMobile" class="inputPhoneNumber" :label="$t('core.address.mobilePhoneNumber')" required></p-phone-field>
        </p-col>
        <p-col class="Landline">
          <p-phone-field v-model="vm.data.contactPerson.phoneNumberHome" class="inputPhoneNumber" :label="$t('core.address.phoneNumber')"></p-phone-field>
        </p-col>
        <p-col class="Business">
          <p-phone-field v-model="vm.data.contactPerson.phoneNumberCompany" class="inputPhoneNumber" :label="$t('kfmv-zh.address.businessPhoneNumber')"></p-phone-field>
        </p-col>
      </p-row>
      <p-row class="AddressWrapper">
        <p-row class="StreetWrapper">
          <p-col class="Street">
            <p-text-field v-model="vm.data.contactPerson.street" class="inputStreet" :label="$t('core.address.street')" required></p-text-field>
          </p-col>
          <p-col class="StreetNumber">
            <p-text-field v-model="vm.data.contactPerson.houseNumber" class="inputStreetNumber" :label="$t('core.address.houseNumber')"></p-text-field>
          </p-col>
        </p-row>
        <p-row class="CityWrapper">
          <p-col class="Zip">
            <p-text-field v-model="vm.data.contactPerson.postalCode" class="inputZip" :label="$t('core.address.postalCode')" required></p-text-field>
          </p-col>
          <p-col class="City">
            <p-text-field v-model="vm.data.contactPerson.city" class="inputCity" :label="$t('core.address.city')" required></p-text-field>
          </p-col>
        </p-row>
      </p-row>
      <p-row class="accessibilitiesWrapper">
        <p-col class="accessibilities">
          <p-checkbox-group
            v-if="vm.accessibilities"
            v-model="vm.data.accessibilities"
            class="inputAccessibility"
            :label="$t('kfmv-zh.legal-cases-debtors.accessibilities')"
            :items="vm.accessibilities.items"
            required
          ></p-checkbox-group>
        </p-col>
      </p-row>
      <p-row class="FormCustomFieldsWrapper">
        <p-col class="SektionPersonName">
          <p-text-field
            v-model="vm.data.legalCase_SectionPersonName"
            class="sektionPersonNameField"
            required
            :label="$t('kfmv-zh.sektionPersonName')"
          ></p-text-field>
        </p-col>
        <p-col class="SektionPersonEmail">
          <p-text-field
            v-model="vm.data.legalCase_SectionPersonEmail"
            class="sektionPersonNameEmailField"
            required
            :label="$t('kfmv-zh.sektionPersonEmail')"
          ></p-text-field>
        </p-col>
        <p-col class="SektionPersonPhoneField">
          <p-phone-field
            v-model="vm.data.legalCase_SectionPersonPhone"
            class="inputPhoneNumber"
            required
            :label="$t('kfmv-zh.sektionPersonPhone')"
          ></p-phone-field>
        </p-col>
        <p-col class="SektionServicePartner">
          <p-select
            v-if="vm.sections"
            v-model="vm.data.legalCase_ServicePartnerId"
            class="inputServicePartner"
            :items="vm.sections.items"
            :label="$t('kfmv-zh.sektionServicePartner')"
            required
          ></p-select>
        </p-col>
      </p-row>
      <p-row class="MembershipInformationWrapper">
        <p-col class="Entry">
          <p-date-field
            v-model="vm.data.legalCase_MemberEntryDate"
            required
            class="inputEntry"
            display-formatter="D.M.YYYY"
            :label="$t('kfmv-zh.legal-cases-debtors.membershipInformationEntry')"
            :placeholder="$format.localDate(vm.maxDateOfBirthMax)"
          ></p-date-field>
        </p-col>
        <p-col class="Exit">
          <p-date-field
            v-model="vm.data.legalCase_MemberExitDate"
            class="inputExit"
            display-formatter="D.M.YYYY"
            :label="$t('kfmv-zh.legal-cases-debtors.membershipInformationExit')"
            :placeholder="$format.localDate(vm.maxDateOfBirthMax)"
          ></p-date-field>
        </p-col>
        <p-col class="OpenFees">
          <p-checkbox v-model="vm.data.legalCase_IsContributionsArePaid" class="inputOpenFees" :label="$t('kfmv-zh.legal-cases-debtors.membershipInformationEntryOP')" required></p-checkbox>
        </p-col>
      </p-row>
      <p-row class="FactsWrapper">
        <p-col class="Facts">
          <p-textarea v-model="vm.data.description" class="inputFacts" :label="$t('kfmv-zh.legal-cases-debtors.facts')"></p-textarea>
        </p-col>
      </p-row>
      <p-row class="DocumentsWrapper">
        <p-col class="Documents">
          <p-file-drop
            v-model="vm.Documents"
            max="5"
            max-file-size="10"
            :label="$t('kfmv-zh.legal-cases-debtors.documents')"
            :messages="$t('kfmv-zh.legal-cases-debtors.documentsinfo')"
          ></p-file-drop>
          <p-alert :value="vm.DocumentsToBig" type="error">
            {{ $t('kfmv-zh.legal-cases-debtors.maxDocumentsSizeInfo') }}
          </p-alert>
        </p-col>
      </p-row>
      <p-row class="AgbWrapper">
        <p-col class="Agb">
          <p-checkbox v-model="vm.data.acceptGdpr" class="inputAgb" :label="$t('kfmv-zh.legal-cases-debtors.agbLabel')" required>
            <span v-html="$t('kfmv-zh.legal-cases-debtors.agb')"></span>
          </p-checkbox>
        </p-col>
      </p-row>
      <p-row class="CaptchaWrapper">
        <p-col class="Captcha">
          <vue-recaptcha ref="recaptcha" :sitekey="vm.reCaptchaSitekey" @verify="onVerify" @expired="onVerify(undefined)"></vue-recaptcha>
        </p-col>
      </p-row>
      <p-row class="ProgressWrapper">
        <p-col class="Progress">
          <p-progress-linear v-if="vm.isLoading" :indeterminate="true" class="progress"></p-progress-linear>
        </p-col>
      </p-row>
      <p-row class="ErrorWrapper">
        <p-col class="Error">
          <p-request-alert v-model="vm.data.errors"></p-request-alert>
        </p-col>
      </p-row>
      <p-row class="ActionWrapper">
        <p-col class="Action">
          <p-button class="inputCancel" :disabled="vm.isLoading">
            {{ $t('core.app.cancel') }}
            <p-icon right>
              cancel
            </p-icon>
          </p-button>
          <p-button class="inputSend" :disabled="vm.isLoading" @click="vm.save()">
            {{ $t('core.app.send') }}
            <p-icon right>
              send
            </p-icon>
          </p-button>
        </p-col>
      </p-row>
    </p-container>
  </p-form>
  <p-container v-else-if="vm.isLoading" class="RegistrationLegalCaseDebtorsLoadingWrapper">
    <p-row class="ProgressWrapper">
      <p-col class="Progress">
        <p-progress-linear v-if="vm.isLoading" :indeterminate="true" class="progress"></p-progress-linear>
      </p-col>
    </p-row>
  </p-container>
  <p-container v-else class="RegistrationLegalCaseDebtorsSuccessWrapper">
    <p-row class="TitleSuccessWrapper">
      <p-col class="Title">
        <h1 class="headerTitle">
          {{ $t('kfmv-zh.legal-cases-debtors.title') }}
        </h1>
      </p-col>
    </p-row>
    <p-row class="TeaserSuccessWrapper">
      <p-col class="TeaserSuccess" v-html="$t('kfmv-zh.legal-cases-debtors.teaserSuccess')">
      </p-col>
    </p-row>
  </p-container>
</template>
<script lang="ts">
  import Vue from 'vue';
  import LegalCasesDebtorsViewModel from './legal-cases-debtors.viewmodel';

  export default Vue.extend({
    name: 'LegalCases',
    layout: 'layout-widget',
    data: () => ({
      vm: new LegalCasesDebtorsViewModel(false),
    }),
    methods: {
      onVerify(response:string | null | undefined) {
        this.vm.data.recaptchaToken = response;
        this.vm.setIsLoading(false);
      },
    },
  });
</script>
