<template>
  <p-container>
    <p-row>
      <p-col xs12 sm6 md4 lg3>
        <p-card fill-height>
          <h3>
            {{ $t('page.cases.dashboard.legalCases.title') }}
          </h3>
          <span class="caption-1 my-4">
            {{ $t('page.cases.dashboard.legalCases.description') }}
          </span>
          <p-row align-end>
            <p-button @click="gotoLegalCases">
              {{ $t('page.cases.dashboard.legalCases.action') }}
            </p-button>
          </p-row>
        </p-card>
      </p-col>
      <p-col xs12 sm6 md4 lg3>
        <p-card fill-height>
          <h3>
            {{ $t('page.cases.dashboard.wageConsultings.title') }}
          </h3>
          <span class="caption-1 my-4">
            {{ $t('page.cases.dashboard.wageConsultings.description') }}
          </span>
          <p-row align-end>
            <p-button @click="gotoWageConsultings">
              {{ $t('page.cases.dashboard.wageConsultings.action') }}
            </p-button>
          </p-row>
        </p-card>
      </p-col>
    </p-row>
  </p-container>
</template>

<script lang="ts">
  import Vue from 'vue';

  export default Vue.extend({
    name: '',
    components: { },
    auth: true,
    props: {
    },
    data: () => ({
    }),
    beforeMount() {
    },
    methods: {
      gotoLegalCases() {
        this.$router.push('/kfmv/il/cases/legalCases/debtors');
      },
      gotoWageConsultings() {
        this.$router.push('/kfmv/il/cases/wageConsultings/debtors');
      },
    },
  });
</script>
