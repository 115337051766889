import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import UsersApi from '../generated/api/Users';

export default (service: Servicelayer) => ({
  ...UsersApi(service),

  // Add custom calls here

});
