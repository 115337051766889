// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import WageConsultingListResourceViewModel from '../../../../viewModel/resource/WageConsultingListResourceViewModel';
import WageConsultingListResourceDTO from '../../dto/resource/WageConsultingListResourceDTO';

export default abstract class WageConsultingListResourceModel extends BaseModel<WageConsultingListResourceDTO> {
  /**
  * @type {int64}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  * @type {int64}
  */
  get creatorId() { return this.dto.creatorId; }
  set creatorId(value) { this.dto.creatorId = value; }
  /**
  */
  get creatorName() { return this.dto.creatorName; }
  set creatorName(value) { this.dto.creatorName = value; }
  /**
  * @type {date-time}
  */
  get created() { return this.dto.created; }
  set created(value) { this.dto.created = value; }
  /**
  * @type {int64}
  */
  get modifierId() { return this.dto.modifierId; }
  set modifierId(value) { this.dto.modifierId = value; }
  /**
  */
  get modifierName() { return this.dto.modifierName; }
  set modifierName(value) { this.dto.modifierName = value; }
  /**
  * @type {date-time}
  */
  get modified() { return this.dto.modified; }
  set modified(value) { this.dto.modified = value; }
  /**
  * @type {int32}
  */
  get number() { return this.dto.number; }
  set number(value) { this.dto.number = value; }
  /**
  */
  get caption() { return this.dto.caption; }
  set caption(value) { this.dto.caption = value; }
  /**
  */
  get status() { return this.dto.status; }
  set status(value) { this.dto.status = value; }
  /**
  * @type {int32}
  */
  get totalTimeBilledMinutes() { return this.dto.totalTimeBilledMinutes; }
  set totalTimeBilledMinutes(value) { this.dto.totalTimeBilledMinutes = value; }
  /**
  * @type {int32}
  */
  get totalTimeUnbilledMinutes() { return this.dto.totalTimeUnbilledMinutes; }
  set totalTimeUnbilledMinutes(value) { this.dto.totalTimeUnbilledMinutes = value; }
  /**
  * @type {date-time}
  */
  get billedUntil() { return this.dto.billedUntil; }
  set billedUntil(value) { this.dto.billedUntil = value; }
  /**
  */
  get debtor() { return this.dto.debtor; }
  set debtor(value) { this.dto.debtor = value; }
  /**
  */
  get customer() { return this.dto.customer; }
  set customer(value) { this.dto.customer = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.id);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.id = value;
    value = Number.parseFloat(qs.creatorid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.creatorId = value;
    this.dto.creatorName = qs.creatorname;
    this.dto.created = qs.created;
    value = Number.parseFloat(qs.modifierid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.modifierId = value;
    this.dto.modifierName = qs.modifiername;
    this.dto.modified = qs.modified;
    value = Number.parseFloat(qs.number);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.number = value;
    this.dto.caption = qs.caption;
    this.dto.status = qs.status;
    value = Number.parseFloat(qs.totaltimebilledminutes);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.totalTimeBilledMinutes = value;
    value = Number.parseFloat(qs.totaltimeunbilledminutes);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.totalTimeUnbilledMinutes = value;
    this.dto.billedUntil = qs.billeduntil;
    this.dto.debtor = qs.debtor;
    this.dto.customer = qs.customer;
  }
}
