// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import WorkItemDossierTypeViewModel from '../../../../viewModel/resource/WorkItemDossierTypeViewModel';
import TimeRecordImportRequestViewModel from '../../../../viewModel/resource/TimeRecordImportRequestViewModel';
import TimeRecordImportRequestDTO from '../../dto/resource/TimeRecordImportRequestDTO';

export default abstract class TimeRecordImportRequestModel extends BaseModel<TimeRecordImportRequestDTO> {
  /**
  * @type {int32}
  */
  get dossierType() { return this.dto.dossierType; }
  set dossierType(value) { this.dto.dossierType = value; }
  /**
  * @type {int64}
  */
  get workItemId() { return this.dto.workItemId; }
  set workItemId(value) { this.dto.workItemId = value; }
  /**
  * @type {date-time}
  */
  get startDate() { return this.dto.startDate; }
  set startDate(value) { this.dto.startDate = value; }
  /**
  * @type {date-time}
  */
  get endDate() { return this.dto.endDate; }
  set endDate(value) { this.dto.endDate = value; }
  /**
  * @type {date-time}
  */
  get createdOrModifiedAfter() { return this.dto.createdOrModifiedAfter; }
  set createdOrModifiedAfter(value) { this.dto.createdOrModifiedAfter = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.dossiertype);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.dossierType = value;
    value = Number.parseFloat(qs.workitemid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.workItemId = value;
    this.dto.startDate = qs.startdate;
    this.dto.endDate = qs.enddate;
    this.dto.createdOrModifiedAfter = qs.createdormodifiedafter;
  }
}
