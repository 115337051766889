// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ResourceLinkViewModel from '../../../../viewModel/resource/ResourceLinkViewModel';
import IntegrationCallListResourceViewModel from '../../../../viewModel/resource/IntegrationCallListResourceViewModel';
import IntegrationCallListResourceDTO from '../../dto/resource/IntegrationCallListResourceDTO';

export default abstract class IntegrationCallListResourceModel extends BaseModel<IntegrationCallListResourceDTO> {
  /**
  * @type {int64}
  */
  get id() { return this.dto.id; }
  set id(value) { this.dto.id = value; }
  /**
  */
  get links() { return this.getArrayModels(() => this.dto.links, ResourceLinkViewModel)!; }
  set links(value) { this.setArrayModels(() => this.dto.links, value); }
  /**
  * @type {int64}
  */
  get creatorId() { return this.dto.creatorId; }
  set creatorId(value) { this.dto.creatorId = value; }
  /**
  */
  get creatorName() { return this.dto.creatorName; }
  set creatorName(value) { this.dto.creatorName = value; }
  /**
  * @type {date-time}
  */
  get created() { return this.dto.created; }
  set created(value) { this.dto.created = value; }
  /**
  * @type {int64}
  */
  get modifierId() { return this.dto.modifierId; }
  set modifierId(value) { this.dto.modifierId = value; }
  /**
  */
  get modifierName() { return this.dto.modifierName; }
  set modifierName(value) { this.dto.modifierName = value; }
  /**
  * @type {date-time}
  */
  get modified() { return this.dto.modified; }
  set modified(value) { this.dto.modified = value; }
  /**
  * @type {int64}
  */
  get integrationTaskId() { return this.dto.integrationTaskId; }
  set integrationTaskId(value) { this.dto.integrationTaskId = value; }
  /**
  */
  get integrationTaskName() { return this.dto.integrationTaskName; }
  set integrationTaskName(value) { this.dto.integrationTaskName = value; }
  /**
  * @type {int64}
  */
  get sourceAppSystemId() { return this.dto.sourceAppSystemId; }
  set sourceAppSystemId(value) { this.dto.sourceAppSystemId = value; }
  /**
  */
  get sourceAppSystemName() { return this.dto.sourceAppSystemName; }
  set sourceAppSystemName(value) { this.dto.sourceAppSystemName = value; }
  /**
  */
  get integrationObject() { return this.dto.integrationObject; }
  set integrationObject(value) { this.dto.integrationObject = value; }
  /**
  */
  get integrationObjectSummary() { return this.dto.integrationObjectSummary; }
  set integrationObjectSummary(value) { this.dto.integrationObjectSummary = value; }
  /**
  * @type {int64}
  */
  get integrationStatusId() { return this.dto.integrationStatusId; }
  set integrationStatusId(value) { this.dto.integrationStatusId = value; }
  /**
  */
  get integrationStatusName() { return this.dto.integrationStatusName; }
  set integrationStatusName(value) { this.dto.integrationStatusName = value; }
  /**
  */
  get integrationStatusMessage() { return this.dto.integrationStatusMessage; }
  set integrationStatusMessage(value) { this.dto.integrationStatusMessage = value; }
  /**
  * @type {int32}
  */
  get runNo() { return this.dto.runNo; }
  set runNo(value) { this.dto.runNo = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    const qs = {} as Record<string, string>;
    Object.keys(queries).forEach((key) => { qs[key.toLowerCase()] = queries[key]; });
    let value: any;
    value = Number.parseFloat(qs.id);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.id = value;
    value = Number.parseFloat(qs.creatorid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.creatorId = value;
    this.dto.creatorName = qs.creatorname;
    this.dto.created = qs.created;
    value = Number.parseFloat(qs.modifierid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.modifierId = value;
    this.dto.modifierName = qs.modifiername;
    this.dto.modified = qs.modified;
    value = Number.parseFloat(qs.integrationtaskid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.integrationTaskId = value;
    this.dto.integrationTaskName = qs.integrationtaskname;
    value = Number.parseFloat(qs.sourceappsystemid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.sourceAppSystemId = value;
    this.dto.sourceAppSystemName = qs.sourceappsystemname;
    this.dto.integrationObject = qs.integrationobject;
    this.dto.integrationObjectSummary = qs.integrationobjectsummary;
    value = Number.parseFloat(qs.integrationstatusid);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.integrationStatusId = value;
    this.dto.integrationStatusName = qs.integrationstatusname;
    this.dto.integrationStatusMessage = qs.integrationstatusmessage;
    value = Number.parseFloat(qs.runno);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.runNo = value;
  }
}
