import Vue from 'vue';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import DocumentsApi from '../generated/api/Documents';

export default (service: Servicelayer) => ({
  ...DocumentsApi(service),

  // Add custom calls here

});
